<template>
  <div class="tl-select">
    <select
      class="tl-select__select"
      name="videoFrameRate"
      id="videoFrameRate"
      :value="value"
      @change="$emit('input', $event)"
      :attrs="$attrs"
    >
      <option v-for="option in options" :value="option.value" :key="option.value">
        {{ option.label }}
      </option>
    </select>
    <label class="tl-select__label" for="videoFrameRate">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'BaseSelect',
  inheritAttrs: false,
  props: {
    value: {},
    label: { type: String, default: '' },
    options: { type: Array, default: () => [] },
  },
};
</script>

<style lang="sass" scoped>
.tl-select
  &__select
    appearance: none
    color: inherit
    font-family: inherit
    line-height: inherit
    margin: 0
    width: 100%
    outline: none
    border: 1px solid #C1C1C1
    border-radius: 5px
    padding-top: 0.625rem
    padding-right: 2.5rem
    padding-bottom: 0.625rem
    padding-left: 0.5rem
    font-size: 0.875rem
    background-color: white
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")
    background-position: right .5rem center
    background-repeat: no-repeat
    background-size: 1.5em 1.5em

  &__label
    display: block
    font-size: 0.8rem
    text-align: center
</style>
