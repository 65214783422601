var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100 w-100" }, [
    _c(
      "div",
      {
        ref: "canvasContainer",
        staticClass: "h-100 w-100 d-flex align-items-center"
      },
      [
        _c("canvas", {
          ref: "canvas",
          staticClass: "mx-auto",
          staticStyle: { "max-width": "100%", "max-height": "100%" }
        })
      ]
    ),
    _c(
      "div",
      { staticClass: "loader", class: { "loader--hidden": !_vm.loading } },
      [
        _c("div", { staticClass: "loader__overlay" }),
        _c("div", { staticClass: "loader__information" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("lang.cargando_imagenes.msg")) +
              " " +
              _vm._s(_vm.loadedCount) +
              " de " +
              _vm._s(_vm.images.length) +
              " "
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }