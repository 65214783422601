<template>
  <Modal :open="open" @close="closeModal" :title="$t('lang.descargar.msg')">

    <b-overlay :show="show" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label">{{ $t('lang.en_proceso.msg') }}</p>
        </div>
      </template>
    <p>{{ $t('lang.estas_seguro.msg') }}</p>
    
    <div class="mt-4">
      <p class="mb-2">{{ $t('lang.selecciona_calidad.msg') }}</p>

      <div class="form-check py-2" v-for="item in qualityList" :key="item.id">
        <input
          class="form-check-input"
          type="radio"
          name="quality"
          :id="`quality_${item.id}`"
          :value="item.id"
          v-model="quality"
        />
        <label class="form-check-label" :for="`quality_${item.id}`">
          <span>{{ item.label }}</span>
          <span class="small text-black-50"> {{ item.dimensions }}</span>
        </label>
      </div>
    </div>

    <div class="mt-4 d-flex justify-content-center align-items-center">
      <BaseButton secondary @click="closeModal">{{ $t('lang.cancelar.msg') }}</BaseButton>

      <BaseButton class="ml-4" @click="handleCreateVideo" :disabled="loading">{{ $t('lang.generar.msg') }}</BaseButton>
    </div>
  </b-overlay>

    <div class="error-messages mt-4 alert alert-danger" v-show="errors.any()">
      <div class="error-messages__message" v-for="(error, index) in errors.all()" :key="index">
        {{ error }}
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';
import Errors from '@/util/Errors';
import BaseButton from '@/components/BaseButton';
import Modal from '@/components/Modal';

export default {
  name: 'CreateVideoModal',
  components: {
    Modal,
    BaseButton,
  },
  props: {
    open: { type: Boolean, default: false },
    range: { type: Object, required: true },
    camera: { type: Object, required: true },
    selectionStrategy: { type: String, required: true },
    frameRate: { type: Number, required: true },
  },
  data() {
    return {
      quality: 'original',
      loading: false,
      show:false,
      qualityList: [
        { id: 'original', label: 'Original', dimensions: '' },
        { id: 'hd', label: 'HD', dimensions: '' },
        { id: 'fhd', label: 'full HD', dimensions: '' },
        { id: '2k', label: '2k', dimensions: '' },
        { id: 'qhd', label: 'QHD', dimensions: '' },
      ],
      errors: new Errors(),
    };
  },
  computed: {
    ...mapState('project', ['project']),
  },
  methods: {
    closeModal() {
      this.errors.clear();
      this.$emit('close');
      this.show = false;
    },
    closeGenerar(){
      this.errors.clear();
      this.$emit('closeGenerar');
      this.show = false;
    },
    async handleCreateVideo() {
      this.loading = true;
      this.errors.clear();
      this.show = true;
      try {
        // this.closeGenerar();
        // return false;
        const videosProcesados = await store.dispatch('project/fetchProjectVideos', this.project.id);
        const result = videosProcesados.data.filter(x => x.status=='pending' || x.status=='in-progress').length
        //console.log("🚀 ~ file: CreateVideoModal.vue ~ line 99 ~ handleCreateVideo ~ result", result)
        if(result >= 3){
          this.errors.capture({ error: this.$t('lang.error_solo3_videos.msg') });
          return;
        }



        await this.$store.dispatch('camera/createVideo', {
          camera: this.camera,
          start: this.range.start,
          end: this.range.end,
          selectionStrategy: this.selectionStrategy,
          frameRate: this.frameRate,
          quality: this.quality,
        });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.errors.capture(error.response.data.errors);
          return;
        }
        this.errors.capture({ error: this.$t('lang.error.msg') });
        console.error(error);
        return;
      } finally {
        this.loading = false;
        this.show = false;
      }

      const notification = {
        //
        type: 'success',
        message: this.$t('lang.generando.msg'),
      };
      this.show = false;
      this.$store.dispatch('notification/add', notification, { root: true });
      this.closeGenerar();
    },
  },
};
</script>
