var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      staticStyle: { background: "#242424" },
      on: { cambio: _vm.changeLenguage },
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-lg-flex justify-content-lg-between" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between d-lg-none"
                    },
                    [
                      _c(
                        "b-button-group",
                        { staticClass: "mx-auto", attrs: { size: "sm" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "camera-home",
                                  params: {
                                    id: _vm.camera.camera.id,
                                    esPublico: _vm.isPublic
                                      ? "public"
                                      : undefined
                                  }
                                },
                                variant: "outline-primary"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("lang.image.msg")))]
                          ),
                          _c("b-button", { attrs: { variant: "primary" } }, [
                            _vm._v("Timelapse")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass:
                            "btn-circle btn-line p-0 d-flex align-items-center justify-content-center flex-col",
                          attrs: {
                            variant: _vm.showSubmenu ? "primary" : "white"
                          },
                          on: {
                            click: function($event) {
                              _vm.showSubmenu = !_vm.showSubmenu
                            }
                          }
                        },
                        [_c("i", { staticClass: "tmlps-icn-back h3 mb-0" })]
                      )
                    ],
                    1
                  ),
                  _c("div", {
                    staticClass: "d-lg-none divider mt-2 d-lg-none"
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-start justify-content-center mt-2 d-lg-inline-flex mt-lg-0"
                    },
                    [
                      !_vm.isPublic
                        ? _c("TenantLogo", { staticClass: "d-none d-lg-block" })
                        : _vm._e(),
                      _c("CameraSelectorButton", {
                        staticClass: "ml-lg-3 flex-shrink-0",
                        attrs: {
                          active: _vm.activeDrawer === "camera-drawer",
                          camera: _vm.camera.camera,
                          disabled: _vm.isPublic
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggleDrawer("camera-drawer")
                          }
                        }
                      }),
                      _c(
                        "b-button-group",
                        {
                          staticClass:
                            "d-none ml-lg-3 d-lg-block flex-lg-shrink-0"
                        },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "camera-home",
                                  params: {
                                    id: _vm.camera.camera.id,
                                    esPublico: _vm.isPublic
                                      ? "public"
                                      : undefined
                                  }
                                },
                                variant: "outline-primary"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("lang.image.msg")))]
                          ),
                          _c("b-button", { attrs: { variant: "primary" } }, [
                            _vm._v("Timelapse")
                          ])
                        ],
                        1
                      ),
                      _c("DateRangeSelectorButton", {
                        staticClass: "ml-3",
                        attrs: {
                          startDate: _vm.range.start,
                          endDate: _vm.range.end
                        },
                        on: { click: _vm.handleShowDateRangeModal }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center mt-2 d-lg-inline-flex align-items-lg-start mt-lg-0 flex-lg-grow-1 ml-lg-3"
                    },
                    [
                      _c("SequencePlayerTimeline", {
                        staticClass: "flex-grow-1",
                        attrs: {
                          "total-frames": _vm.totalFrames - 1,
                          "current-time": _vm.currentTime
                        },
                        on: {
                          "start-seek": _vm.handleStartSeek,
                          input: _vm.handleSeek
                        },
                        model: {
                          value: _vm.currentFrame,
                          callback: function($$v) {
                            _vm.currentFrame = $$v
                          },
                          expression: "currentFrame"
                        }
                      }),
                      _c("PlayButton", {
                        staticClass: "ml-4",
                        attrs: {
                          disabled: _vm.loadingImages || _vm.images.length === 0
                        },
                        on: {
                          play: _vm.handlePlayButton,
                          pause: _vm.handlePauseButton
                        },
                        model: {
                          value: _vm.play,
                          callback: function($$v) {
                            _vm.play = $$v
                          },
                          expression: "play"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSubmenu,
                          expression: "showSubmenu"
                        }
                      ],
                      staticClass: "d-lg-block"
                    },
                    [
                      _c("div", { staticClass: "options-container" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center mt-2 mt-lg-0"
                          },
                          [
                            _c("VideoSettingSelectionStrategySelect", {
                              attrs: { cambio: _vm.change },
                              model: {
                                value: _vm.selectionStrategy,
                                callback: function($$v) {
                                  _vm.selectionStrategy = _vm._n($$v)
                                },
                                expression: "selectionStrategy"
                              }
                            }),
                            _c("VideoSettingFrameRateSelect", {
                              staticClass: "ml-3",
                              attrs: { cambio: _vm.change },
                              model: {
                                value: _vm.frameRate,
                                callback: function($$v) {
                                  _vm.frameRate = _vm._n($$v)
                                },
                                expression: "frameRate"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center mt-2 ml-md-3 mt-lg-0"
                          },
                          [
                            _vm.selectionStrategy !== "all" && !_vm.isPublic
                              ? _c("CreateVideoButton", {
                                  attrs: {
                                    disabled:
                                      _vm.loadingImages ||
                                      _vm.images.length === 0
                                  },
                                  on: { click: _vm.handleShowCreateVideoModal }
                                })
                              : _vm._e(),
                            !_vm.isPublic
                              ? _c("MenuButton", {
                                  staticClass: "ml-3",
                                  on: { click: _vm.handleMenuButtonClick }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showVideoEstatus
        ? _c("AvisoVideosEstatus", {
            attrs: { addNuevo: _vm.addVideoxGenerar },
            on: {
              close: function($event) {
                _vm.showVideoEstatus = false
              }
            }
          })
        : _vm._e(),
      _c("ProjectSelectorButton", { attrs: { esPublico: _vm.isPublic } }),
      _c("ImageSequencePlayer", {
        ref: "sequencePlayerRef",
        attrs: {
          images: _vm.images,
          "frame-rate": _vm.frameRate,
          autoplay: ""
        },
        on: {
          "loading-start": function($event) {
            _vm.loadingImages = $event
          },
          "loading-end": _vm.handleImagesLoadingEnd,
          progress: _vm.handleSequenceProgress,
          play: function($event) {
            _vm.play = true
          },
          pause: function($event) {
            _vm.play = false
          },
          stop: function($event) {
            _vm.play = false
          }
        }
      }),
      !_vm.isPublic
        ? _c("CameraSelectorDrawer", {
            attrs: {
              project: _vm.project.project,
              "current-camera": _vm.camera.camera,
              show: _vm.activeDrawer === "camera-drawer"
            },
            on: {
              select: _vm.selectCamera,
              close: function($event) {
                _vm.activeDrawer = ""
              }
            }
          })
        : _vm._e(),
      _c("DateRangePickerModal", {
        attrs: {
          open: _vm.showDateRangeModal,
          range: _vm.range,
          "disabled-dates": _vm.disabledDates,
          "max-date": _vm.maxDateString,
          "min-date": _vm.minDateString,
          esPublico: _vm.isPublic
        },
        on: {
          rangeSelected: _vm.handleModalRangeSelection,
          close: function($event) {
            _vm.showDateRangeModal = false
          }
        }
      }),
      _c("CreateVideoModal", {
        attrs: {
          open: _vm.showCreateVideoModal,
          range: { start: _vm.range.start, end: _vm.range.end },
          camera: _vm.camera.camera,
          "selection-strategy": _vm.selectionStrategy,
          "frame-rate": _vm.frameRate
        },
        on: {
          close: function($event) {
            _vm.showCreateVideoModal = false
          },
          closeGenerar: _vm.closeGenerar
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }