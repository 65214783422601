var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-inline-flex flex-column align-items-center justify-content-center"
    },
    [
      _c(
        "button",
        {
          staticClass: "btn-circle btn-line mb-1",
          attrs: { type: "button", disabled: _vm.disabled },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [_c("i", { staticClass: "tmlps-icn-generate h2 m-0" })]
      ),
      _c(
        "div",
        {
          staticClass: "small text-center",
          class: _vm.disabled ? "text-muted" : ""
        },
        [_vm._v(_vm._s(_vm.$t("lang.download.msg")))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }