<template>
  <Modal :open="open" @close="$emit('close')" title="Elige un día o un rango">
    <div class="d-flex justify-content-between">
      <div class="date-label" v-show="localRange.start">
        <div class="date-label__year">{{ startYear }}</div>
        <div class="date-label__date">{{ startFormattedDate }}</div>
      </div>
      <div class="date-label-divider mx-2 align-self-center" v-show="localRange.end"></div>
      <div class="date-label" v-show="localRange.end">
        <div class="date-label__year">{{ endYear }}</div>
        <div class="date-label__date">{{ endFormattedDate }}</div>
      </div>
    </div>

    <div class="mt-4 px-5">
      <VueCtkDateTimePicker
        :class="{'ocultar-menu-calendario': esPublico}"
        inline
        only-date
        format="YYYY-MM-DD"
        no-header
        :max-date="maxDate"
        :min-date="minDate"
        :disabled-dates="disabledDates"
        no-button
        no-shortcuts
        color="var(--primary)"
        range
        locale="es"
        v-model="localRange"
      />
    </div>

    <div class="my-4" v-if="!esPublico">
      <DateRangeShortcutSelector
        :active="shortcutType"
        @select="handleShortcutSelection"
        :maxDate="new Date(maxDate)"
      />
    </div>

    <div class="d-flex justify-content-center">
      <BaseButton @click="handleRangeSelection" :disabled="!localRange.start || !localRange.end">
        Aplicar
      </BaseButton>
    </div>
  </Modal>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import dateUtil from '@/util/date';
import BaseButton from './BaseButton.vue';
import DateRangeShortcutSelector from '@/components/DateRangeShortcutSelector.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'DateRangePickerModal',
  components: { BaseButton, DateRangeShortcutSelector, Modal, VueCtkDateTimePicker },
  props: {
    open: { type: Boolean, default: false },
    range: { type: Object, default: () => ({ start: new Date(), end: new Date() }) },
    disabledDates: { type: Array, default: () => [] },
    maxDate: { type: String, default: new Date().toISOString() },
    minDate: { type: String, default: new Date().toISOString() },
    esPublico: { type: Boolean, default: false },
  },

  data() {
    return {
      localRange: {
        start: dateUtil.toDateString(this.range.start),
        end: dateUtil.toDateString(this.range.start),
      },
      dateFormat: {
        weekday: 'long',
        month: 'short',
        day: 'numeric',
      },
      shortcutType: '',
    };
  },

  watch: {
    range: {
      immediate: true,
      deep: true,
      handler: function(newValue) {
        this.localRange.start = dateUtil.toDateString(newValue.start);
        this.localRange.end = dateUtil.toDateString(newValue.end);
      },
    },
  },

  computed: {
    startYear() {
      return new Date(this.localRange.start).getFullYear();
    },
    startFormattedDate() {
      return this.localRange.start
        ? dateUtil.parseDate(this.localRange.start).toLocaleDateString(undefined, this.dateFormat)
        : '';
    },
    endYear() {
      return new Date(this.localRange.end).getFullYear();
    },
    endFormattedDate() {
      return this.localRange.end
        ? dateUtil.parseDate(this.localRange.end).toLocaleDateString(undefined, this.dateFormat)
        : '';
    },
  },

  methods: {
    handleRangeSelection() {
      const range = {
        start: new Date(dateUtil.parseDate(this.localRange.start)),
        end: new Date(dateUtil.parseDate(this.localRange.end)),
      };

      this.$emit('rangeSelected', range);
      this.$emit('input', range);
    },
    handleShortcutSelection(range) {
      this.localRange.start = dateUtil.toDateString(range.start);
      this.localRange.end = dateUtil.toDateString(range.end);
    },
  },
};
</script>

<style lang="sass" scoped>
.date-label
  &__year
    font-size: 0.875rem

  &__date
    font-size: 1.625rem
    font-weight: bold
    text-transform: capitalize

  &-divider
    height: 3rem
    width: 2px
    background-color: #C1C1C1
</style>

<style>
.ocultar-menu-calendario .datepicker-controls {
  display: none;
}
</style>