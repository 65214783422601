<template>
  <BaseSelect
    :options="options"
    :label="$t('lang.speed.msg')"
    :value="value"
    @input="$emit('input', parseInt($event.target.value, 10))"
  />
</template>

<script>
import BaseSelect from '@/components/BaseSelect';

export default {
  name: 'VideoSettingFrameRateSelect',
  components: { BaseSelect },
  props: ['value','cambio'],
  data() {
    return {
      options: [
        { label: '', value: 12 },
        { label: 'Normal', value: 24 },
        { label: '', value: 48 },
        { label: '', value: 72 },
      ],
    };
  },
  created(){
    console.log(this.cambio,"prueba created");
    this.options[0].label = this.$t('lang.slow.msg');
    this.options[2].label = this.$t('lang.fast.msg');
    this.options[3].label = this.$t('lang.superfast.msg');
  },
  watch:{
    "cambio":function(){
        console.log("probando cambio",this.cambio);
        this.options[0].label = this.$t('lang.slow.msg');
        this.options[2].label = this.$t('lang.fast.msg');
        this.options[3].label = this.$t('lang.superfast.msg');
      
    }
  }
};
</script>
