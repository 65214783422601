<template>
  <div>
    <b-toast
      :visible="true"
      style="margin-left: auto; width: 333px;"
      :solid="true"
      :appendToast="true"
      id="example-toast"
      @hidden="cerrarAviso()"
      title=""
      no-auto-hide
    >
      <div v-for="(video, index) in listaVideos" :key="index">
        <b-spinner
          v-if="video.status == 'pending' || video.status == 'in-progress'"
          style="width: 1.6rem; height: 1.6rem; margin-bottom: -4px;"
          variant="primary"
          label="Spinning"
        ></b-spinner>
        <b-icon
          v-if="video.status == 'success'"
          style="margin-right: 10px;"
          icon="check-circle-fill"
          scale="2"
          variant="success"
        ></b-icon>
        {{ (video.status == 'pending' || video.status == 'in-progress') ? 'El video se esta generando.' : '' }}
        {{ video.status == 'success' ? 'Video generando.' : '' }}
        <hr />
      </div>
    </b-toast>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import store from '@/store';
export default {
  name: 'AvisoProyectosEstatus',
  components: {},
  mixins: [],
  props: {
    value: {},
    addNuevo: {}
  },
  data() {
    return {
      intervalos: null,
      primeraLista: [],
      todosVideos: [],
    };
  },
  async created() {
    this.primeraCarga();
    this.checkStatusJob();
  },
  
  watch: {
    'addNuevo': function(newVal, oldVal) {
      this.primeraCarga();
    }
  },
  computed: {
    ...mapState(['project', 'user']),
    listaVideos() {
      let lista = [];
      if (!this.todosVideos.length) return this.primeraLista;
      for (let i in this.primeraLista) {
        let item = this.primeraLista[i];
        for (let z in this.todosVideos) {
          let itemz = this.todosVideos[z];
          if (item.id == itemz.id) {
            lista.push(itemz);
          }
        }
      }
      return lista;
    },
  },
  mounted() {},
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async primeraCarga(){
      this.primeraLista = await this.obtenerVideos('solo_pendientes');
    },
    async checkStatusJob() {
      var $this = this;
      this.intervalos = setInterval(async () => {
        //console.log('🚀 ~  intervalos', true);
        $this.todosVideos = await $this.obtenerVideos();
      }, 6000);
    },
    async obtenerVideos(tipo = 'todos') {
      return new Promise(async (resolve) => {
        const videosProcesados = await store.dispatch(
          'project/fetchProjectVideos',
          this.project.project.id,
        );
        let lista = [];
        const soloConCreator = videosProcesados.data.filter(
          (x) => x.creator !== null,
        );
        //console.log("🚀 ~ soloConCreator", soloConCreator)

        if (tipo == 'solo_pendientes') {
          lista = soloConCreator.filter(
            (x) =>
              (x.status == 'pending' || x.status == 'in-progress') &&
              x.creator.id == this.user.user.id,
          );
        } else {
          lista = soloConCreator.filter((x) => x.creator.id == this.user.user.id);
        }

        resolve(lista);
      });
    },
    cerrarAviso() {
      clearInterval(this.intervalos);
      this.$emit('close');
    },
  },
};
</script>
<style lang="" scoped></style>
