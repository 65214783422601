<template>
  <div class="d-inline-flex flex-column align-items-center justify-content-center">
    <button
      type="button"
      @click="$emit('click')"
      class="btn-circle btn-line mb-1"
      :disabled="disabled"
    >
      <i class="tmlps-icn-generate h2 m-0"></i>
    </button>
    <div class="small text-center" :class="disabled ? 'text-muted' : ''">{{$t('lang.download.msg')}}</div>
  </div>
</template>

<script>
export default {
  name: 'CreateVideoButton',
  props: {
    disabled: { type: Boolean, default: false },
  },
};
</script>
