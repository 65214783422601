<template>
  <AppLayout style="background: #242424" @cambio="changeLenguage">
    <AvisoVideosEstatus @close="showVideoEstatus = false" v-if="showVideoEstatus" :addNuevo="addVideoxGenerar"/>

    <ProjectSelectorButton :esPublico="isPublic" />

    <ImageSequencePlayer
      ref="sequencePlayerRef"
      :images="images"
      :frame-rate="frameRate"
      autoplay
      @loading-start="loadingImages = $event"
      @loading-end="handleImagesLoadingEnd"
      @progress="handleSequenceProgress"
      @play="play = true"
      @pause="play = false"
      @stop="play = false"
    />

    <CameraSelectorDrawer
      :project="project.project"
      :current-camera="camera.camera"
      :show="activeDrawer === 'camera-drawer'"
      @select="selectCamera"
      @close="activeDrawer = ''"
      v-if="!isPublic"
    />

    <DateRangePickerModal
      :open="showDateRangeModal"
      :range="range"
      :disabled-dates="disabledDates"
      :max-date="maxDateString"
      :min-date="minDateString"
      :esPublico="isPublic"
      @rangeSelected="handleModalRangeSelection"
      @close="showDateRangeModal = false"
    />

    <CreateVideoModal
      :open="showCreateVideoModal"
      :range="{ start: range.start, end: range.end }"
      :camera="camera.camera"
      :selection-strategy="selectionStrategy"
      :frame-rate="frameRate"
      @close="showCreateVideoModal = false"
      @closeGenerar="closeGenerar"
    />

    <template v-slot:actionbar>
      <div class="d-lg-flex justify-content-lg-between">
        <div class="d-flex align-items-center justify-content-between d-lg-none">
          <b-button-group size="sm" class="mx-auto">
            <b-button
              :to="{ name: 'camera-home', params: { id: camera.camera.id, esPublico: isPublic ? 'public' : undefined } }"
              variant="outline-primary"
              >{{$t('lang.image.msg')}}</b-button
            >
            <b-button variant="primary">Timelapse</b-button>
          </b-button-group>

          <b-button
            class="btn-circle btn-line p-0 d-flex align-items-center justify-content-center flex-col"
            :variant="showSubmenu ? 'primary' : 'white'"
            @click="showSubmenu = !showSubmenu"
          >
            <i class="tmlps-icn-back h3 mb-0"></i>
          </b-button>
        </div>

        <div class="d-lg-none divider mt-2 d-lg-none"></div>

        <div class="d-flex align-items-start justify-content-center mt-2 d-lg-inline-flex mt-lg-0">
          <TenantLogo class="d-none d-lg-block" v-if="!isPublic" />

          <CameraSelectorButton
            class="ml-lg-3 flex-shrink-0"
            :active="activeDrawer === 'camera-drawer'"
            :camera="camera.camera"
            @click="toggleDrawer('camera-drawer')"
            :disabled="isPublic"
          />

          <b-button-group class="d-none ml-lg-3 d-lg-block flex-lg-shrink-0">
            <b-button
              :to="{ name: 'camera-home', params: { id: camera.camera.id, esPublico: isPublic ? 'public' : undefined  } }"
              variant="outline-primary"
              >{{$t('lang.image.msg')}}</b-button
            >
            <b-button variant="primary">Timelapse</b-button>
          </b-button-group>

          <DateRangeSelectorButton
            class="ml-3"
            @click="handleShowDateRangeModal"
            :startDate="range.start"
            :endDate="range.end"
          />
        </div>

        <div
          class="d-flex align-items-center mt-2 d-lg-inline-flex align-items-lg-start mt-lg-0 flex-lg-grow-1 ml-lg-3"
        >
          <SequencePlayerTimeline
            class="flex-grow-1"
            v-model="currentFrame"
            :total-frames="totalFrames - 1"
            :current-time="currentTime"
            @start-seek="handleStartSeek"
            @input="handleSeek"
          />

          <PlayButton
            class="ml-4"
            v-model="play"
            @play="handlePlayButton"
            @pause="handlePauseButton"
            :disabled="loadingImages || images.length === 0"
          />
        </div>

        <div v-show="showSubmenu" class="d-lg-block">
          <div class="options-container">
            <div class="d-flex align-items-center justify-content-center mt-2 mt-lg-0">
              <VideoSettingSelectionStrategySelect v-model.number="selectionStrategy"  :cambio="change" />
              <VideoSettingFrameRateSelect class="ml-3" v-model.number="frameRate" :cambio="change" />
            </div>

            <div class="d-flex justify-content-center mt-2 ml-md-3 mt-lg-0">
              <CreateVideoButton 
                v-if="selectionStrategy !== 'all' && !isPublic"
                @click="handleShowCreateVideoModal"
                :disabled="loadingImages || images.length === 0"
              />
              <MenuButton v-if="!isPublic" class="ml-3" @click="handleMenuButtonClick" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import dateUtil from '@/util/date';
import store from '@/store';
import CameraSelectorButton from '@/components/CameraSelectorButton.vue';
import CameraSelectorDrawer from '@/components/CameraSelectorDrawer.vue';
import CreateVideoButton from '@/components/CreateVideoButton.vue';
import CreateVideoModal from '@/components/CreateVideoModal.vue';
import DateRangePickerModal from '@/components/DateRangePickerModal.vue';
import DateRangeSelectorButton from '@/components/DateRangeSelectorButton.vue';
import ImageSequencePlayer from '@/components/ImageSequencePlayer.vue';
import MenuButton from '@/components/MenuButton.vue';
import PlayButton from '@/components/PlayButton.vue';
import ProjectSelectorButton from '@/components/ProjectSelectorButton.vue';
import SequencePlayerTimeline from '@/components/SequencePlayerTimeline.vue';
import TenantLogo from '@/components/TenantLogo.vue';
import VideoSettingFrameRateSelect from '@/components/VideoSettingFrameRateSelect.vue';
import VideoSettingSelectionStrategySelect from '@/components/VideoSettingSelectionStrategySelect.vue';
import AvisoVideosEstatus from '@/components/AvisoVideosEstatus.vue';

export default {
  name: 'CameraVideoHome',
  components: {
    CameraSelectorButton,
    CameraSelectorDrawer,
    CreateVideoButton,
    CreateVideoModal,
    DateRangePickerModal,
    DateRangeSelectorButton,
    ImageSequencePlayer,
    MenuButton,
    PlayButton,
    ProjectSelectorButton,
    SequencePlayerTimeline,
    TenantLogo,
    VideoSettingFrameRateSelect,
    VideoSettingSelectionStrategySelect,
    AvisoVideosEstatus
  },
  props: {
    id: { type: String, required: true },
    initialStartDate: { type: Date, default: () => new Date() },
    initialEndDate: { type: Date, default: () => new Date() },
    initialImages: { type: Array, default: () => [] },
  },
  data() {
    return {
      isPublic: false,
      addVideoxGenerar: 1,
      showVideoEstatus: false,
      activeDrawer: '',
      showDateRangeModal: false,
      showCreateVideoModal: false,
      showSubmenu: false,
      range: {
        start: this.initialStartDate,
        end: this.initialEndDate,
      },
      loadingImages: false,
      images: this.initialImages,
      play: false,
      totalFrames: 0,
      currentFrame: 0,
      currentTime: 0,
      change:null
    };
  },
  computed: {
    ...mapState(['project', 'user', 'camera']),
    frameRate: {
      get() {
        return this.$store.state.camera.videoSettings.frameRate;
      },
      set(value) {
        this.$store.commit('camera/UPDATE_FRAME_RATE', value);
      },
    },
    selectionStrategy: {
      get() {
        return this.$store.state.camera.videoSettings.selectionStrategy;
      },
      set(value) {
        this.$store.commit('camera/UPDATE_SELECTION_STRATEGY', value);
      },
    },
    disabledDates() {
      return this.camera.camera.dates_without_photos;
    },
    minDateString() {
      return this.camera.camera.first_capture_at
        ? dateUtil.toDateString(this.camera.camera.first_capture_at)
        : dateUtil.toDateString();
    },
    maxDateString() {
      return this.camera.camera.last_capture_at
        ? dateUtil.toDateString(this.camera.camera.last_capture_at)
        : dateUtil.toDateString();
    },
  },
  created(){
    this.isPublic = this.$route.params.esPublico == 'public';
    console.log("🚀 created ~ this.isPublic:", this.isPublic)
  },
  methods: {
    closeGenerar(){
      this.showVideoEstatus=true; 
      this.showCreateVideoModal = false; 
      this.addVideoxGenerar++;
    },
    changeLenguage(e){
      console.log("prueba evento para select",e);
      this.change = e;
    },
    /**
     * Squence player event handlers.
     */
    handleImagesLoadingEnd(loadedCount) {
      this.loadingImages = false;
      this.totalFrames = loadedCount;
    },
    handleSequenceProgress(frameIndex) {
      this.currentFrame = frameIndex;
      // Change time only every second
      if (frameIndex % this.frameRate === 0) {
        this.currentTime = parseInt(frameIndex / this.frameRate, 10);
      }
    },
    handlePlayButton() {
      this.$refs.sequencePlayerRef.play();
    },
    handlePauseButton() {
      this.$refs.sequencePlayerRef.pause();
    },
    handleStartSeek() {
      this.$refs.sequencePlayerRef.pause();
    },
    handleSeek(frameIndex) {
      this.$refs.sequencePlayerRef.seekTo(frameIndex);
    },

    selectCamera(camera) {
      if (this.camera.camera.id !== camera.id) {
        this.selectionStrategy = 'express';
        this.$refs.sequencePlayerRef.stop();
        this.$router.push({ name: 'camera-video', params: { id: camera.id } });
      }
    },
    toggleDrawer(drawer) {
      this.activeDrawer = this.activeDrawer === drawer ? '' : drawer;
    },
    handleShowCreateVideoModal() {
      this.showCreateVideoModal = true;
    },
    handleMenuButtonClick() {
      this.$router.push({ name: 'profile-home', params: { project: this.project.project.id } });
    },

    /**
     * Date range modal event handlers.
     */
    handleShowDateRangeModal() {
      this.showDateRangeModal = true;
    },
    handleModalRangeSelection(range) {
      this.showDateRangeModal = false;

      this.selectRange(range);
    },
    formatCaptureTime(date) {
      let fechaTiome = new Date(date).toLocaleDateString();
      return fechaTiome +' '+ new Date(date).toLocaleTimeString(undefined, {
        hour: 'numeric',
        hour12: false,
        minute: 'numeric',
      });
    },
    async selectRange(range) {
      this.range = range;

      this.images = await getPhotos(
        this.camera.camera,
        dateUtil.startOfDay(this.range.start),
        dateUtil.endOfDay(this.range.end),
        this.selectionStrategy,
      );

      for(let i in this.images){
        let item = this.images[i];
        console.log('captured_at', this.formatCaptureTime(item.captured_at));
      }

    },
  },

  /**
   * LIFE CYCLE METHODS
   */
  async beforeRouteUpdate(to, from, next) {
    const camera = this.camera.camera;
    const range = getDateRange(getDateFromCamera(camera));
    this.range.start = range.start;
    this.range.end = range.end;
    this.images = await getPhotos(
      camera,
      range.start,
      range.end,
      this.camera.videoSettings.selectionStrategy,
    );
    next();
  },
  async beforeRouteEnter(to, from, next) {
    const camera = store.state.camera.camera;
    const range = getDateRange(getDateFromCamera(camera));
    to.params.initialStartDate = range.start;
    to.params.initialEndDate = range.end;
    to.params.initialImages = await getPhotos(
      camera,
      range.start,
      range.end,
      store.state.camera.videoSettings.selectionStrategy,
    );
    next();
  },
};

function getDateRange(date) {
  return { start: dateUtil.startOfDay(date), end: dateUtil.endOfDay(date) };
}

function getDateFromCamera(camera) {
  return camera.last_capture_at ? new Date(camera.last_capture_at) : new Date();
}

async function getPhotos(camera, startDate, endDate, selectionStrategy) {
  try {
    return store.dispatch('photo/fetchPhotos', { camera, startDate, endDate, selectionStrategy, esPublico:true });
  } catch (error) {
    store.dispatch('notification/addError', 'Ocurrió un error al descargar las imágenes.', {
      root: true,
    });
    throw error;
  }
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.divider {
  height: 2px;
  background: #c1c1c1;
}

.navbar-buttons {
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: auto;
  }
}

.options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: flex-start;
  }
  @include media-breakpoint-up(lg) {
    margin-left: 1rem;
    display: flex;
  }
}
</style>
