var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { open: _vm.open, title: _vm.$t("lang.descargar.msg") },
      on: { close: _vm.closeModal }
    },
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.show, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "stopwatch",
                          "font-scale": "3",
                          animation: "cylon"
                        }
                      }),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v(_vm._s(_vm.$t("lang.en_proceso.msg")))
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("lang.estas_seguro.msg")))]),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("lang.selecciona_calidad.msg")))
              ]),
              _vm._l(_vm.qualityList, function(item) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "form-check py-2" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.quality,
                          expression: "quality"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "radio",
                        name: "quality",
                        id: "quality_" + item.id
                      },
                      domProps: {
                        value: item.id,
                        checked: _vm._q(_vm.quality, item.id)
                      },
                      on: {
                        change: function($event) {
                          _vm.quality = item.id
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "quality_" + item.id }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(item.label))]),
                        _c("span", { staticClass: "small text-black-50" }, [
                          _vm._v(" " + _vm._s(item.dimensions))
                        ])
                      ]
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass:
                "mt-4 d-flex justify-content-center align-items-center"
            },
            [
              _c(
                "BaseButton",
                { attrs: { secondary: "" }, on: { click: _vm.closeModal } },
                [_vm._v(_vm._s(_vm.$t("lang.cancelar.msg")))]
              ),
              _c(
                "BaseButton",
                {
                  staticClass: "ml-4",
                  attrs: { disabled: _vm.loading },
                  on: { click: _vm.handleCreateVideo }
                },
                [_vm._v(_vm._s(_vm.$t("lang.generar.msg")))]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.any(),
              expression: "errors.any()"
            }
          ],
          staticClass: "error-messages mt-4 alert alert-danger"
        },
        _vm._l(_vm.errors.all(), function(error, index) {
          return _c(
            "div",
            { key: index, staticClass: "error-messages__message" },
            [_vm._v(" " + _vm._s(error) + " ")]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }