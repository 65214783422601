<template>
  <div class="sequence-timeline">
    <input
      type="range"
      step="1"
      :max="totalFrames"
      min="0"
      :value="value"
      @mousedown="$emit('start-seek', parseInt($event.target.value, 10))"
      @mouseup="$emit('end-seek', parseInt($event.target.value, 10))"
      @input="$emit('input', parseInt($event.target.value, 10))"
    />
    <div class="sequence-timeline__label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: 'SequencePlayerTimeline',
  props: {
    value: { type: Number, default: 0 },
    totalFrames: { type: Number, required: true },
    currentTime: { type: Number, default: 0 },
  },
  computed: {
    label() {
      return this.value + 1;
    },
    time() {
      return this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.sequence-timeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  background-color: white;
  box-shadow: 0 0 1rem 1px rgb(0 0 0 / 18%);
  border-radius: 9999px;

  &__label {
    font-size: 0.625rem;
    padding-left: 0.5rem;
    min-width: 1.5rem;
  }

  input[type='range'] {
    width: 100%;
  }

  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
  }
  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-thumb {
    appearance: none;
    background-color: var(--primary);
    width: 15px;
    height: 15px;
    border: 3px solid var(--primary);
    border-radius: 9999px;
    margin-top: -6px;
  }
  input[type='range']::-moz-range-thumb {
    background-color: var(--primary);
    width: 10px;
    height: 10px;
    border: 3px solid var(--primary);
    border-radius: 9999px;
  }
  input[type='range']::-ms-thumb {
    background-color: var(--primary);
    width: 15px;
    height: 15px;
    border: 3px solid var(--primary);
    border-radius: 9999px;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background-color: var(--primary);
    height: 3px;
  }
  input[type='range']::-moz-range-track {
    background-color: var(--primary);
    height: 3px;
  }
  input[type='range']::-ms-track {
    background-color: var(--primary);
    height: 3px;
  }
  input[type='range']::-ms-fill-lower {
    background-color: HotPink;
  }
  input[type='range']::-ms-fill-upper {
    background-color: black;
  }
}
</style>
