<template>
  <div class="h-100 w-100">
    <div class="h-100 w-100 d-flex align-items-center" ref="canvasContainer">
      <canvas ref="canvas" class="mx-auto" style="max-width: 100%; max-height: 100%"></canvas>
    </div>

    <div class="loader" :class="{ 'loader--hidden': !loading }">
      <div class="loader__overlay"></div>
      <div class="loader__information">
        {{ $t('lang.cargando_imagenes.msg') }} {{ loadedCount }} de {{ images.length }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImageSequencePlayer',
  props: {
    images: { type: Array, default: () => [] },
    value: { type: Boolean, default: false },
    frameRate: { type: Number, default: 24 },
    autoplay: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
      loadedCount: 0,
      // index: 0,
      frames: [],
      // rAF: null,
      // timeout: null,
      isPlaying: false,
    };
  },

  watch: {
    images: function() {
      this.loadImages();
    },
  },

  computed: {
    loadedPercentage() {
      return Math.round((this.loadedCount / this.images.length) * 100);
    },
    framesInMilli() {
      return Math.ceil(1000 / this.frameRate);
    },
    canvasContext() {
      return this.$refs.canvas.getContext('2d', { alpha: false });
    },
  },

  methods: {
    /** Event listener for loaded images */
    imageLoaded() {
      this.loadedCount++;
      this.$emit('loading-progress', this.loadedCount);

      // set the loading state to false when all images are loaded
      if (this.loadedCount === this.images.length) {
        this.loading = false;
        this.$emit('loading-end', this.frames.length);
        this.seekTo(0);

        this.$emit('duration-update', this.duration);

        if (this.autoplay) {
          this.play();
        }
      }
    },

    /** Make the browser to load all images */
    loadImages() {
      this.loadedCount = 0;
      this.frames = [];

      this.stop();

      if (this.images.length === 0) {
        console.log('images.length', this.images.length);
        this.notifySuccess();
        return;
      }

      this.loading = true;
      this.$emit('loading-start', this.loading);

      this.images.forEach((item) => {
        let image = new Image();
        image.addEventListener('load', this.imageLoaded, false);
        image.src = item.optimized_url;
        this.frames.unshift(image);
      });
    },
    notifySuccess() {
      const notification = {
        type: 'error',
        message: 'No hay resultados con el rango seleccionado',
        position: 'top'
      };
      this.$store.dispatch('notification/add', notification, { root: true });
    },
    play() {
      if (this._index === this.frames.length) {
        this.rewindToFirstFrame();
      }

      this.isPlaying = true;
      this.$emit('play');
      this.startSequence();
    },

    pause() {
      if (!this.isPlaying) {
        return;
      }

      clearTimeout(this._timeout);
      cancelAnimationFrame(this._rAF);
      this.isPlaying = false;
      this.$emit('pause');
    },

    stop() {
      clearTimeout(this._timeout);
      cancelAnimationFrame(this._rAF);
      this.isPlaying = false;
      this.$emit('stop');
    },

    startSequence() {
      if (this._index >= this.frames.length) {
        this.pause();
        return;
      }

      this._timeout = setTimeout(() => {
        this.seekTo(this._index);
        this._index++;
        this._rAF = requestAnimationFrame(this.startSequence.bind(this));
        // this.startSequence();
      }, this.framesInMilli);
    },

    seekTo(frameIndex) {
      if (this.frames.length === 0) {
        return;
      }

      if (frameIndex > this.frames.length - 1) {
        return;
      }

      // this.clearFrame();
      this._index = frameIndex;
      this.drawFrame(this.frames[frameIndex]);
      this.$emit('progress', this._index);
    },

    drawFrame(frame) {
      const {
        width: canvasWidth,
        height: canvasHeight,
      } = this.$refs.canvasContainer.getBoundingClientRect();

      const wRatio = canvasWidth / frame.width;
      const hRatio = canvasHeight / frame.height;
      const minRatio = Math.min(wRatio, hRatio);
      const newWidth = frame.width * minRatio;
      const newHeight = frame.height * minRatio;

      this.$refs.canvas.width = newWidth;
      this.$refs.canvas.height = newHeight;
      this.$refs.canvas
        .getContext('2d', { alpha: false })
        .drawImage(frame, 0, 0, Math.floor(newWidth), Math.floor(newHeight));
    },

    rewindToFirstFrame() {
      this._index = 0;
    },

    clearFrame() {
      const { width, height } = this.$refs.canvas.getBoundingClientRect();
      this.$refs.canvas.getContext('2d', { alpha: false }).clearRect(0, 0, width, height);
    },
  },

  mounted() {
    this._index = 0;
    this.loadImages();
  },

  beforeDestroy() {
    this.stop();
  },
};
</script>

<style lang="sass" scoped>
.loader
  $self: &
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  display: flex
  align-items: center
  justify-content: center
  visibility: visible

  &--hidden
    visibility: hidden

  &__overlay
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

  &__information
    padding: 2.5rem
    background-color: var(--primary)
    border-radius: 0.5rem
    color: white
    min-width: 25rem
    text-align: center
    font-size: 1.25rem
    position: relative
</style>
