var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sequence-timeline" }, [
    _c("input", {
      attrs: { type: "range", step: "1", max: _vm.totalFrames, min: "0" },
      domProps: { value: _vm.value },
      on: {
        mousedown: function($event) {
          _vm.$emit("start-seek", parseInt($event.target.value, 10))
        },
        mouseup: function($event) {
          _vm.$emit("end-seek", parseInt($event.target.value, 10))
        },
        input: function($event) {
          _vm.$emit("input", parseInt($event.target.value, 10))
        }
      }
    }),
    _c("div", { staticClass: "sequence-timeline__label" }, [
      _vm._v(_vm._s(_vm.label))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }