<template>
  <div class="d-flex align-items-center justify-content-center flex-wrap">
    <div class="py-2 px-2">
      <ToggleButton
        class="py-0 px-2 shortcut-button"
        :active="selectedShortcut === 'today'"
        :disabled="shouldBeDisabled('today')"
        @click="handleShortcutSelection('today')"
      >
        {{ $t('lang.hoy.msg') }}
      </ToggleButton>
    </div>

    <div class="py-2 px-2">
      <ToggleButton
        class="py-0 px-2 shortcut-button"
        :active="selectedShortcut === 'yesterday'"
        :disabled="shouldBeDisabled('yesterday')"
        @click="handleShortcutSelection('yesterday')"
      >
        Ayer
      </ToggleButton>
    </div>

    <div class="py-2 px-2">
      <ToggleButton
        class="py-0 px-2 shortcut-button"
        :active="selectedShortcut === '7days'"
        :disabled="shouldBeDisabled('7days')"
        @click="handleShortcutSelection('7days')"
      >
        7 Días
      </ToggleButton>
    </div>

    <div class="py-2 px-2">
      <ToggleButton
        class="py-0 px-2 shortcut-button"
        :active="selectedShortcut === '30days'"
        :disabled="shouldBeDisabled('30days')"
        @click="handleShortcutSelection('30days')"
      >
        30 Días
      </ToggleButton>
    </div>

    <div class="py-2 px-2">
      <ToggleButton
        class="py-0 px-2 shortcut-button"
        :active="selectedShortcut === 'this-month'"
        :disabled="shouldBeDisabled('this-month')"
        @click="handleShortcutSelection('this-month')"
      >
        Este mes
      </ToggleButton>
    </div>

    <div class="py-2 px-2">
      <ToggleButton
        class="py-0 px-2 shortcut-button"
        :active="selectedShortcut === 'last-month'"
        :disabled="shouldBeDisabled('last-month')"
        @click="handleShortcutSelection('last-month')"
      >
        Mes anterior
      </ToggleButton>
    </div>
  </div>
</template>

<script>
import ToggleButton from '@/components/ToggleButton.vue';

export default {
  components: { ToggleButton },
  props: {
    active: { type: Boolean, default: false },
    maxDate: { type: Date, default: () => new Date() },
  },
  data() {
    return {
      selected: null,
      shortcuts: {
        today: () => ({ start: new Date(), end: new Date() }),
        yesterday: () => {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          return { start: date, end: date };
        },
        '7days': () => {
          const date = new Date();
          date.setDate(date.getDate() - 7);
          return { start: date, end: new Date() };
        },
        '30days': () => {
          const date = new Date();
          date.setDate(date.getDate() - 30);
          return { start: date, end: new Date() };
        },
        'this-month': () => {
          const today = new Date();
          return {
            start: new Date(today.getFullYear(), today.getMonth(), 1),
            end: new Date(today.getFullYear(), today.getMonth() + 1, 0),
          };
        },
        'last-month': () => {
          const today = new Date();
          return {
            start: new Date(today.getFullYear(), today.getMonth() - 1, 1),
            end: new Date(today.getFullYear(), today.getMonth(), 0),
          };
        },
      },
    };
  },
  computed: {
    selectedShortcut() {
      return this.active ? this.selected : null;
    },
  },
  methods: {
    handleShortcutSelection(shortcut) {
      this.selected = shortcut;

      this.$emit('select', this.shortcuts[shortcut]());
    },
    shouldBeDisabled(shortcut) {
      // const range = this.shortcuts[shortcut]();

      // if (range.start <= new Date(this.maxDate + 1)) {
      //   return false;
      // }

      //return true;
      return false;
    },
  },
};
</script>

<style lang="sass" scoped>
.shortcut-button
  font-size: 0.75rem
  width: 6.25rem
</style>
