var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ToggleButton",
    {
      staticClass: "d-flex align-items-center justify-content-center",
      attrs: { active: _vm.active },
      on: {
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c(
        "svg",
        {
          attrs: {
            height: "18",
            width: "18",
            fill: "none",
            stroke: "currentColor",
            viewBox: "0 0 24 24"
          }
        },
        [
          _c("path", {
            attrs: {
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "stroke-width": "2",
              d:
                "M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            }
          })
        ]
      ),
      _c("span", { staticClass: "ml-3 font-weight-bold" }, [
        _vm._v(_vm._s(_vm.label))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }