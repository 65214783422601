var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tl-select" }, [
    _c(
      "select",
      {
        staticClass: "tl-select__select",
        attrs: {
          name: "videoFrameRate",
          id: "videoFrameRate",
          attrs: _vm.$attrs
        },
        domProps: { value: _vm.value },
        on: {
          change: function($event) {
            return _vm.$emit("input", $event)
          }
        }
      },
      _vm._l(_vm.options, function(option) {
        return _c(
          "option",
          { key: option.value, domProps: { value: option.value } },
          [_vm._v(" " + _vm._s(option.label) + " ")]
        )
      }),
      0
    ),
    _c(
      "label",
      { staticClass: "tl-select__label", attrs: { for: "videoFrameRate" } },
      [_vm._v(_vm._s(_vm.label))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }