<template>
  <ToggleButton
    :active="active"
    @click="$emit('click')"
    class="d-flex align-items-center justify-content-center"
  >
    <svg height="18" width="18" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      ></path>
    </svg>
    <span class="ml-3 font-weight-bold">{{ label }}</span>
  </ToggleButton>
</template>

<script>
import ToggleButton from '@/components/ToggleButton';

export default {
  components: {
    ToggleButton,
  },
  props: {
    active: { type: Boolean, default: false },
    startDate: { type: Date, required: false },
    endDate: { type: Date, required: false },
  },
  computed: {
    label() {
      if (!this.startDate || !this.endDate) {
        return 'Elige un dia o rango';
      }

      const formatOptions = { month: 'short', year: 'numeric', day: 'numeric' };
      const formattedStartDate = this.startDate.toLocaleDateString(undefined, formatOptions);
      const formattedEndDate = this.endDate.toLocaleDateString(undefined, formatOptions);

      return `${formattedStartDate} - ${formattedEndDate}`;
    },
  },
};
</script>
