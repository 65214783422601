var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { open: _vm.open, title: "Elige un día o un rango" },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.localRange.start,
                expression: "localRange.start"
              }
            ],
            staticClass: "date-label"
          },
          [
            _c("div", { staticClass: "date-label__year" }, [
              _vm._v(_vm._s(_vm.startYear))
            ]),
            _c("div", { staticClass: "date-label__date" }, [
              _vm._v(_vm._s(_vm.startFormattedDate))
            ])
          ]
        ),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.localRange.end,
              expression: "localRange.end"
            }
          ],
          staticClass: "date-label-divider mx-2 align-self-center"
        }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.localRange.end,
                expression: "localRange.end"
              }
            ],
            staticClass: "date-label"
          },
          [
            _c("div", { staticClass: "date-label__year" }, [
              _vm._v(_vm._s(_vm.endYear))
            ]),
            _c("div", { staticClass: "date-label__date" }, [
              _vm._v(_vm._s(_vm.endFormattedDate))
            ])
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "mt-4 px-5" },
        [
          _c("VueCtkDateTimePicker", {
            class: { "ocultar-menu-calendario": _vm.esPublico },
            attrs: {
              inline: "",
              "only-date": "",
              format: "YYYY-MM-DD",
              "no-header": "",
              "max-date": _vm.maxDate,
              "min-date": _vm.minDate,
              "disabled-dates": _vm.disabledDates,
              "no-button": "",
              "no-shortcuts": "",
              color: "var(--primary)",
              range: "",
              locale: "es"
            },
            model: {
              value: _vm.localRange,
              callback: function($$v) {
                _vm.localRange = $$v
              },
              expression: "localRange"
            }
          })
        ],
        1
      ),
      !_vm.esPublico
        ? _c(
            "div",
            { staticClass: "my-4" },
            [
              _c("DateRangeShortcutSelector", {
                attrs: {
                  active: _vm.shortcutType,
                  maxDate: new Date(_vm.maxDate)
                },
                on: { select: _vm.handleShortcutSelection }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex justify-content-center" },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: !_vm.localRange.start || !_vm.localRange.end },
              on: { click: _vm.handleRangeSelection }
            },
            [_vm._v(" Aplicar ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }