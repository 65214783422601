var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-toast",
        {
          staticStyle: { "margin-left": "auto", width: "333px" },
          attrs: {
            visible: true,
            solid: true,
            appendToast: true,
            id: "example-toast",
            title: "",
            "no-auto-hide": ""
          },
          on: {
            hidden: function($event) {
              return _vm.cerrarAviso()
            }
          }
        },
        _vm._l(_vm.listaVideos, function(video, index) {
          return _c(
            "div",
            { key: index },
            [
              video.status == "pending" || video.status == "in-progress"
                ? _c("b-spinner", {
                    staticStyle: {
                      width: "1.6rem",
                      height: "1.6rem",
                      "margin-bottom": "-4px"
                    },
                    attrs: { variant: "primary", label: "Spinning" }
                  })
                : _vm._e(),
              video.status == "success"
                ? _c("b-icon", {
                    staticStyle: { "margin-right": "10px" },
                    attrs: {
                      icon: "check-circle-fill",
                      scale: "2",
                      variant: "success"
                    }
                  })
                : _vm._e(),
              _vm._v(
                " " +
                  _vm._s(
                    video.status == "pending" || video.status == "in-progress"
                      ? "El video se esta generando."
                      : ""
                  ) +
                  " " +
                  _vm._s(video.status == "success" ? "Video generando." : "") +
                  " "
              ),
              _c("hr")
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }