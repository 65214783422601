var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center justify-content-center flex-wrap"
    },
    [
      _c(
        "div",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "ToggleButton",
            {
              staticClass: "py-0 px-2 shortcut-button",
              attrs: {
                active: _vm.selectedShortcut === "today",
                disabled: _vm.shouldBeDisabled("today")
              },
              on: {
                click: function($event) {
                  return _vm.handleShortcutSelection("today")
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.hoy.msg")) + " ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "ToggleButton",
            {
              staticClass: "py-0 px-2 shortcut-button",
              attrs: {
                active: _vm.selectedShortcut === "yesterday",
                disabled: _vm.shouldBeDisabled("yesterday")
              },
              on: {
                click: function($event) {
                  return _vm.handleShortcutSelection("yesterday")
                }
              }
            },
            [_vm._v(" Ayer ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "ToggleButton",
            {
              staticClass: "py-0 px-2 shortcut-button",
              attrs: {
                active: _vm.selectedShortcut === "7days",
                disabled: _vm.shouldBeDisabled("7days")
              },
              on: {
                click: function($event) {
                  return _vm.handleShortcutSelection("7days")
                }
              }
            },
            [_vm._v(" 7 Días ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "ToggleButton",
            {
              staticClass: "py-0 px-2 shortcut-button",
              attrs: {
                active: _vm.selectedShortcut === "30days",
                disabled: _vm.shouldBeDisabled("30days")
              },
              on: {
                click: function($event) {
                  return _vm.handleShortcutSelection("30days")
                }
              }
            },
            [_vm._v(" 30 Días ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "ToggleButton",
            {
              staticClass: "py-0 px-2 shortcut-button",
              attrs: {
                active: _vm.selectedShortcut === "this-month",
                disabled: _vm.shouldBeDisabled("this-month")
              },
              on: {
                click: function($event) {
                  return _vm.handleShortcutSelection("this-month")
                }
              }
            },
            [_vm._v(" Este mes ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "ToggleButton",
            {
              staticClass: "py-0 px-2 shortcut-button",
              attrs: {
                active: _vm.selectedShortcut === "last-month",
                disabled: _vm.shouldBeDisabled("last-month")
              },
              on: {
                click: function($event) {
                  return _vm.handleShortcutSelection("last-month")
                }
              }
            },
            [_vm._v(" Mes anterior ")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }