<template>
  <BaseSelect
    :options="options"
    :label="$t('lang.photos.msg')"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
import BaseSelect from '@/components/BaseSelect';

export default {
  name: 'VideoSettingSelectionSelect',
  components: { BaseSelect },
  props: {
    value: { type: String },
    cambio:Boolean
  },
  data() {
    return {
      options: [
        { label: '', value: 'all' },
        { label: '', value: 'express' },
      ],
    };
  },
  created(){
    this.options[0].label = this.$t('lang.all.msg')
    this.options[1].label = this.$t('lang.abstract.msg')
  },
  watch:{
    "cambio":function(){
        console.log("probando cambio",this.cambio);
        this.options[0].label = this.$t('lang.all.msg')
        this.options[1].label = this.$t('lang.abstract.msg')
      
    }
  }
};
</script>
